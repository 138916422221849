const server = {
  //host: 'http://localhost:1337',
  host: 'https://apply.filrougecapital.com',
}

const user = {
  username: '',
  id: '',
  token: '',
}

export { server, user }
