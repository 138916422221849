import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import { server, user } from 'src/GlobalData'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/Login'))
const Logout = React.lazy(() => import('./views/Logout'))

const isLoggedIn = () => {
  let username = localStorage.getItem('username')
  let id = localStorage.getItem('id')
  user.username = username
  user.id = id
  if (username && id) return true
  return false
}

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Montserrat'].join(','),
  },
})

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <HashRouter>
          <Suspense fallback={<CSpinner color="primary" />}>
            <Switch>
              <Route exact path="/logout" name="Logout" render={(props) => <Logout {...props} />} />
              <Route
                path="/"
                name="Home"
                render={(props) => (isLoggedIn() ? <DefaultLayout {...props} /> : <Login />)}
              />
            </Switch>
          </Suspense>
        </HashRouter>
      </ThemeProvider>
    )
  }
}

export default App
